import { apiUcld, apiUserAddPrac, apiUserAiduoDel } from '@/api/api.js'
import AudioTool from '@/components/AudioTool/AudioTool.vue'
import Recorder from '@/components/Recorder/Recorder.vue'
import VueVideo from '@/components/vue-video.vue'
import * as upload from '@/utils/upload'

export default {
  components: {
    AudioTool,
    Recorder,
    VueVideo
  },
  data() {
    return {
      resultData: [],
      ucldId: sessionStorage.getItem('tcl_ucld_id'),
      PccourseDetailTitle: sessionStorage.getItem('tcl_pccourse-detail_title'),
      userPractice: '',
      Delisible: false,
      del_item: '',
      del_index: '',
      del_pindex: '',
      answerVisible: false
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      this.getUcld()
    },
    getUcld() {
      let paramsBase = {
        ucld_id: this.ucldId,
        lang: this.$i18n.locale
      }
      apiUcld(paramsBase).then((res) => {
        this.resultData = res.data
        this.resultData.next_course = Object.values(this.resultData.next_course)
        console.log(this.resultData)
      })
    },
    load() {
      this.page++
      this.getSkillData()
    },
    getMsgFormSon(data) {
      var name = this.getMath() + this.getMath() + this.getMath() + '.wav'
      upload.file({
        file: data,
        fileName: name,
        onSuccess: ({ data }) => {
          this.submitPrac(data.oss_url)
        }
      })
    },
    //生成随机数
    getMath() {
      return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
    },
    submitPrac(urlValue) {
      let paramsBase = {
        ucld_id: this.resultData.id,
        contents: urlValue,
        type: 1,
        lang: this.$i18n.locale
      }
      apiUserAddPrac(paramsBase).then((res) => {
        // this.resultData.user_practice_unit.push({
        //     content: urlValue,
        //     id: res.data.puid,
        // })
        this.getUcld()
      })
    },
    submit() {
      if (this.userPractice === '') {
        this.$alert('作答的内容不能为空，请重新输入', '提醒', {
          confirmButtonText: '确定',
          callback: (action) => {
            this.$message({
              type: 'info',
              message: `action: ${action}`
            })
          }
        })
      } else {
        let paramsBase = {
          ucld_id: this.resultData.id,
          contents: this.userPractice,
          type: 1,
          lang: this.$i18n.locale
        }
        apiUserAddPrac(paramsBase).then((res) => {
          // this.resultData.user_practice_unit.push({
          //     content: this.userPractice,
          //     id: res.data.puid,
          // })
          this.getUcld()
        })
      }
    },
    clickDelAudio(item, index, pindex) {
      this.del_item = item
      this.del_index = index
      this.del_pindex = pindex
      this.Delisible = true
    },
    clickDel() {
      this.resultData.user_practice_unit[this.del_pindex].content.splice(
        this.del_index,
        1
      )
      let paramsBase = {
        puid: this.del_item.id
      }
      apiUserAiduoDel(paramsBase).then((res) => {
        //this.resultData.user_practice_unit.splice(this.del_index, 1)
      })
      this.Delisible = false
    },
    clickAgainExercise(id) {
      sessionStorage.setItem('tcl_ucld_id', id)
      let paramsBase = {
        ucld_id: id,
        lang: this.$i18n.locale
      }
      apiUcld(paramsBase).then((res) => {
        this.resultData = res.data
        this.resultData.next_course = Object.values(this.resultData.next_course)
      })
    },
    againExercise() {
      this.resultData.user_practice_unit = []
      this.userPractice = ''
    },
    viewAnswer() {
      this.answerVisible = true
    }
  }
}
