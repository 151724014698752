var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('div',{staticClass:"header-frame"},[_c('span',{staticClass:"title history",on:{"click":function($event){return _vm.$router.push('/Skill')}}},[_vm._v(_vm._s(_vm.$t('m.enhanceSkill')))]),_c('span',{staticClass:"title history",on:{"click":function($event){return _vm.$router.push('/SkillDetails')}}},[_c('span',{staticClass:"title-value",attrs:{"title":_vm.PccourseDetailTitle}},[_vm._v(_vm._s(_vm.PccourseDetailTitle))])]),_c('span',{staticClass:"title now"},[_vm._v(_vm._s(_vm.resultData.title))])]),_c('div',{staticClass:"result-frame"},[_c('div',{staticClass:"content"},[(_vm.resultData.video)?_c('div',{staticClass:"video-tool"},[_c('VueVideo',{attrs:{"src":_vm.resultData.video,"cover":_vm.resultData.cover}})],1):_vm._e(),_c('div',[_vm._v(_vm._s(_vm.resultData.content))])]),(_vm.resultData.example)?_c('div',{staticClass:"content"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('m.teacherExample')))]),(_vm.resultData.example)?_c('div',{staticClass:"audio-tool"},[_c('AudioTool',{attrs:{"audioUrl":_vm.resultData.example}})],1):_vm._e()]):_vm._e(),_c('div',{staticClass:"content"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('m.practiceRequirements')))]),_c('p',{staticClass:"values"},[_vm._v(_vm._s(_vm.resultData.demand))]),_c('div',{staticClass:"pracicehead"},[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t('m.myPractice')))]),(_vm.resultData.user_type === 'text')?_c('div',{staticClass:"button-submit"},[_c('el-button',{attrs:{"type":"danger is-round"},on:{"click":_vm.viewAnswer}},[_vm._v(_vm._s(_vm.$t('gre.submit')))]),(_vm.resultData.user_practice_unit.length)?_c('el-button',{attrs:{"type":"danger is-round"},on:{"click":_vm.againExercise}},[_vm._v(_vm._s(_vm.$t('m.againAnswer')))]):_vm._e()],1):_vm._e()]),(
          _vm.resultData.user_practice_unit &&
            _vm.resultData.user_practice_unit.length > 0
        )?[(_vm.resultData.user_type == 'text')?[_vm._l((_vm.resultData.user_practice_unit),function(item,index){return _c('div',{key:index,staticClass:"values"},[_c('div',{staticClass:"answer-time"},[_vm._v(_vm._s(item.time))]),_vm._l((item.content),function(items,indexs){return _c('div',{key:indexs,staticClass:"answer-content"},[_vm._v(" "+_vm._s(items.content)+" ")])})],2)}),(
              _vm.resultData.next_course !== undefined &&
                _vm.resultData.next_course.length > 0
            )?_c('div',{staticClass:"button-box"},[_c('el-button',{attrs:{"type":"danger is-round"},on:{"click":function($event){return _vm.clickAgainExercise(_vm.resultData.next_course[1])}}},[_vm._v(_vm._s(_vm.$t('gre.nextknowledgePoint'))+":"+_vm._s(_vm.resultData.next_course[0]))])],1):_vm._e()]:[_vm._l((_vm.resultData.user_practice_unit),function(item,index){return _c('div',{key:index,staticClass:"audio-tool audio-del"},[_c('div',{staticClass:"answer-time"},[_vm._v(_vm._s(item.time))]),_vm._l((item.content),function(items,indexs){return _c('div',{key:indexs,staticClass:"answer-audio"},[_c('AudioTool',{attrs:{"audioUrl":items.content}}),_c('div',{staticClass:"del-audio",on:{"click":function($event){return _vm.clickDelAudio(items, indexs, index)}}},[_c('img',{attrs:{"src":require('@/assets/icon/icon_del.svg'),"alt":""}})])],1)})],2)}),(_vm.resultData.user_practice_unit.length > 0)?_c('div',{staticClass:"submit-frame"}):_vm._e(),_c('div',[_c('Recorder',{on:{"func":_vm.getMsgFormSon}})],1),(
              _vm.resultData.next_course !== undefined &&
                _vm.resultData.next_course.length > 0
            )?_c('div',{staticClass:"button-box"},[_c('el-button',{attrs:{"type":"danger is-round"},on:{"click":function($event){return _vm.clickAgainExercise(_vm.resultData.next_course[1])}}},[_vm._v(_vm._s(_vm.$t('gre.nextknowledgePoint'))+":"+_vm._s(_vm.resultData.next_course[0]))])],1):_vm._e()]]:_c('div',{staticClass:"not-data-frame"},[(_vm.resultData.user_type == 'text')?[_c('el-input',{attrs:{"type":"textarea","rows":6,"placeholder":_vm.$t('placeholder.clickHereToAnswer')},model:{value:(_vm.userPractice),callback:function ($$v) {_vm.userPractice=$$v},expression:"userPractice"}}),_c('div',{staticClass:"button-box"},[_c('el-button',{attrs:{"type":"danger is-round"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t('gre.submits')))])],1)]:[_c('div',[_c('Recorder',{on:{"func":_vm.getMsgFormSon}})],1)],(
            _vm.resultData.next_course !== undefined &&
              _vm.resultData.next_course.length > 0
          )?_c('div',{staticClass:"button-box"},[_c('el-button',{attrs:{"type":"danger is-round"},on:{"click":function($event){return _vm.clickAgainExercise(_vm.resultData.next_course[1])}}},[_vm._v(_vm._s(_vm.$t('gre.nextknowledgePoint'))+":"+_vm._s(_vm.resultData.next_course[0]))])],1):_vm._e()],2)],2)]),_c('el-dialog',{staticClass:"dialog-Popup",attrs:{"visible":_vm.Delisible,"width":"320px"},on:{"update:visible":function($event){_vm.Delisible=$event}}},[_c('p',{staticClass:"tips"},[_vm._v("您是否要删除此次练习？")]),_c('div',{staticClass:"option-frame"},[_c('span',{staticClass:"feifan-btn pink",on:{"click":function($event){return _vm.clickDel()}}},[_vm._v("确定")]),_c('span',{staticClass:"feifan-btn gray",on:{"click":function($event){_vm.Delisible = false}}},[_vm._v("取消")])])]),_c('el-dialog',{attrs:{"custom-class":'dialog-download-app',"visible":_vm.answerVisible,"close-on-click-modal":false,"modal":false},on:{"close":function($event){_vm.answerVisible = false}}},[(_vm.resultData.answer == null)?_c('div',[_vm._v(" 暂无答案 ")]):_c('div',[_vm._v(" "+_vm._s(_vm.resultData.answer)+" ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }