<template>
  <div @click="clickVideo" class="vue-video">
    <vue-core-video-player
      v-bind="$attrs"
      ref="video"
      :src="src"
      :autoplay="autoplay"
      controls="auto"
      autopictureinpicture
      @timeupdate="handleTime"
      @loadeddata="handleLoaded"
    ></vue-core-video-player>
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  props: {
    src: '',
    cover: null,
    autoplay: {
      type: Boolean,
      default: false
    },
    onTime: {
      type: Function,
      default: () => {}
    },
    onLoaded: {
      type: Function,
      default: () => {}
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.$refs.video.$el.addEventListener(
        'enterpictureinpicture',
        this.enterpictureinpicture
      )
      this.$refs.video.$el.addEventListener(
        'leavepictureinpicture',
        this.leavepictureinpicture
      )
      if (this.cover) {
        this.$refs.video.$el.children[0].poster = this.cover
      }
      this.$refs.video.$children[1].$children[1].$children[2].volume = 100
    })
  },
  beforeDestroy() {
    if (this.$refs.video) {
      this.$refs.video.$el.removeEventListener(
        'enterpictureinpicture',
        this.enterpictureinpicture
      )
      this.$refs.video.$el.removeEventListener(
        'leavepictureinpicture',
        this.leavepictureinpicture
      )
      if (document.pictureInPictureElement) {
        document.exitPictureInPicture().catch((error) => {
          console.log(error)
        })
      }
    }
  },
  methods: {
    clickVideo(e) {
      if (e.target.className === 'vue-core-video-player-layers') {
        if (this.$refs.video.isPlaying) {
          this.$refs.video.pause()
        } else {
          this.$refs.video.play()
        }
      }
    },
    leavepictureinpicture(e) {
      this.$emit('showDialog', true)
    },
    enterpictureinpicture() {
      this.$emit('showDialog', false)
    },

    handleTime(data) {
      this.onTime(data)
    },
    handleLoaded(data) {
      this.onLoaded(data)
    }
  }
}
</script>

<style lang="scss" scoped>
.vue-video {
  ::v-deep .vcp-container {
    background-color: #fff;
  }
}
</style>
